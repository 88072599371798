<template>
    <div>
      <img
        v-for="(ticket, index) in tickets"
        :key="index"
        :src="ticket.closed"
        @click="openPhotoBrowser(index)"
      />
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            tickets: [
            {
                closed: "static/images/task25-riddle-blue-closed.jpg",
                opened: "static/images/task25-riddle-blue.jpg"
            },
            {
                closed: "static/images/task25-riddle-green-closed.jpg",
                opened: "static/images/task25-riddle-green.jpg"
            },
            {
                closed: "static/images/task25-riddle-orange-closed.jpg",
                opened: "static/images/task25-riddle-orange.jpg"
            },
            {
                closed: "static/images/task25-riddle-purple-closed.jpg",
                opened: "static/images/task25-riddle-purple.jpg"
            },
            {
                closed: "static/images/task25-riddle-red-closed.jpg",
                opened: "static/images/task25-riddle-red.jpg"
            },
            {
                closed: "static/images/task25-riddle-yellow-closed.jpg",
                opened: "static/images/task25-riddle-yellow.jpg"
            }
            ]
        };
        },
    methods: {
      openPhotoBrowser(index) {
        const photos = [
          {
            url: this.tickets[index].opened,
            caption: "Los " + (index + 1)
          }
        ];
  
        this.$f7.photoBrowser.create({
          photos,
          theme: "dark",
          type: "standalone",
          toolbar: false,
          popupCloseLinkText: "Schließen"
        }).open();
      }
    }
  };
  </script>
  <style scoped>
  img{
    max-height: 40px;
  }
  </style>