<template>
    <f7-panel right resizable theme-dark>
      <f7-view>
        <f7-page>
          <f7-list>
            <f7-list-button title="Über das Spiel" popup-open=".about-popup"></f7-list-button>
           <!-- <f7-list-item title="Partner" link="#"></f7-list-item>-->
            <f7-list-item title="Spiel zurücksetzen" v-on:click="resetApp"></f7-list-item>
            <f7-list-button title="Feedback geben" popup-open=".report-popup"></f7-list-button>
          </f7-list>

          <f7-toolbar bottom style="text-align: center">
            <f7-block>{{$store.state.userId}}</f7-block>
          </f7-toolbar>
        </f7-page>
      </f7-view>
    </f7-panel>
</template>
<script>
module.exports = {
    methods: {
      resetApp: function(){
        this.$f7.dialog.confirm("Das Spiel wirklich zurücksetzen? Dein aktueller Spielstand geht verloren!", "Zurücksetzen", function(){
          localStorage.clear();
          location.reload();
        });
        
      }
    }
}
</script>


<style scoped>
    .panel {
        min-width: 100px;
        max-width: 90vw;
    }
</style>


