<template>
	<f7-link :tab-link="tabLink" @click="tabChange">
		<f7-icon :ios="iosIcon" :aurora="auroraIcon" :md="mdIcon">
			<f7-badge v-show="notificationCount>0" color="red">{{notificationCount}}</f7-badge>
		</f7-icon>
		<span class="tabbar-label">{{label}}</span>
			
	</f7-link>
</template>
<script>
module.exports = {
	props: ['id','tabLink','iosIcon','auroraIcon','mdIcon','label'],
	data: function (){
		return {
		}
	},
	computed: {
		notificationCount () {
		  if(this.$store.state[this.id]){
			  var path = this.$store.state[this.id];
			  return path.notificationCount ? path.notificationCount : 0;
		  } else{
			  return 0;
		  }
		}
	},
	methods: {
		tabChange: function(){
			this.$store.commit("notificationCount", {name:this.id, value:0});
		}
	}
	
}
</script>


<style scoped>
</style>