<template>
  <f7-app :params="f7params" >
	  <!-- Views/Tabs container -->
	  <f7-views tabs class="safe-areas">
		<!-- Tabbar for switching views-tabs -->
		<f7-toolbar tabbar labels bottom>
		  <navbar-item
		  	id="home"
		  	tab-link="#view-home"
		  	ios-icon="f7:square_list" 
		  	aurora-icon="f7:square_list" 
		  	md-icon="material:list"
		  	label="Home">
		  </navbar-item>
		<navbar-item
		  	id="map"
		  	tab-link="#view-map"
		  	ios-icon="f7:map" 
		  	aurora-icon="f7:map" 
		  	md-icon="material:map"
		  	label="Karte">
		  </navbar-item>
		  <navbar-item
		  	id="chat"
		  	tab-link="#view-chat"
		  	ios-icon="f7:chat_bubble_text" 
		  	aurora-icon="f7:chat_bubble_text" 
		  	md-icon="material:chat"
		  	label="Chat"
        >
		  </navbar-item>
      <navbar-item
        v-for="customPage in config.customPages" 
        v-if="customPage.displayed"
		  	:id="customPage.id"
		  	:tab-link="customPage.tabLink"
		  	:ios-icon="customPage.iosIcon" 
		  	:aurora-icon="customPage.iosIcon" 
		  	:md-icon="customPage.mdIcon"
		  	:label="customPage.label"
        >
		  </navbar-item>

		</f7-toolbar>

		<!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
		<f7-view id="view-home" class="view-main" main tab  tab-active url="/"></f7-view>

		<!-- Map View -->
		<f7-view id="view-map" ref="map" tab name="map"  url="/map/"></f7-view>

		<!-- Chat View -->
		<f7-view id="view-chat" name="chat" tab url="/chat/"></f7-view>

    <!-- Custom Views -->
		<f7-view
      v-for="customPage in config.customPages"  
      :id="'view-'+customPage.id" 
      :name="customPage.label" tab 
      :url="customPage.path"  
      v-if="customPage.displayed"
    ></f7-view>

	  </f7-views>
    <!-- Right resizable Panel with Cover effect and dark layout theme -->
    <side-menu></side-menu>

    <report-popup></report-popup>
    <about-popup></about-popup>
    <finish-popup></finish-popup>
    <version-chooser-popup></version-chooser-popup>
  </f7-app>
</template>

<script>
  import { Device }  from '../js/framework7-custom.js';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';
  import NavbarItem from './navbar/navbar-item.vue';
  import SideMenu from './menu/side-menu.vue';
	import AboutPopup from '../components/popup/about.vue';
  import ReportPopup from '../components/popup/report.vue';
  import FinishPopup from '../components/popup/finish.vue';
  import VersionChooserPopup from './popup/version-chooser.vue';
	import config from '../config.json';
  import { VueOfflineMixin } from 'vue-offline'


  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'io.framework7.myapp', // App bundle ID
          name: 'Schnitzeljagd', // App name
          theme: 'auto', // Automatic theme detection

          // App routes
          routes: routes,

          // Register service worker
          serviceWorker: Device.cordova ? {} : {
            path: '/service-worker.js',
          },
          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
        },
        config: config,
        isInitialised: false 
      }
    },
	components: {
    NavbarItem,
    SideMenu,
    AboutPopup,
    ReportPopup,
    FinishPopup,
    VersionChooserPopup
  },
  mixins: [VueOfflineMixin],
  computed: {},
    mounted() {
      var that = this;
      this.$f7ready((f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (Device.cordova) {
          cordovaApp.init(f7);
        }
        if(this.$store && this.$store.state && this.$store.state.version && this.$store.state.version && !this.$store.state.version.includes("-")){
          this.$store.commit("reset");
        }
        if(this.isVersionChoosen()){

          var popup = f7.popup.get(".version-chooser-popup");
          if(popup){
            f7.popup.get(".version-chooser-popup").open();

            // Prevent skipping the version choosing with back button
            f7.popup.get(".version-chooser-popup").on('popupClose', function (popup) {
              if(this.isVersionChoosen()){
                popup.open();
              }
            }.bind(this));
          }
        }
      });
      if(!this.$store.state.userId){
        var userId = (Math.random().toString(36).substr(2, 9));
        
        var that = this; 
        var model = "";
        var get = { 
            type: 'user',
            id: userId,
            useragent: navigator.userAgent,
            device: "desktop"
          };
        var that = this; 
        this.$store.state.userId = userId;
        document.addEventListener("deviceready", onDeviceReady, false);
        function onDeviceReady() {
          
          if(device && device.model){
            get.device = device.model;
          }
          
          that.sendGetRequest(get);
          that.isInitialised = true; 
        }
        
       setTimeout(() => { 
          if(!that.isInitialised){
            that.sendGetRequest(window.serverURL, get);
            that.isInitialised = true; 
          }
        }, 5000);
      }

      this.$on('online', () => {
        this.sendSavedRequests();
      });
      this.sendSavedRequests();
    },
    methods:{
      isVersionChoosen: function(){
        return this.config.init.versions && Object.keys(this.config.init.versions).length && !this.$store.state.version;
      },
      sendSavedRequests: function(){
        this.$store.getters.savedRequests.forEach(request => {
          this.$f7.request.get(window.serverURL, request);
          this.$store.commit("deleteRequest",request.id);
          console.log("deleted request "+request.id);
        });
      }
    }
    }
</script>

<style lang="less">
  body {
      background: #e6e6e6;
  }
  div#framework7-root {
      max-width: 700px;
      margin: 0 auto;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }
  .display-block.card-header.text-color-white {
    overflow: initial;
    width: calc(~"100% - 40px");
  }

  .panel-right .list-button {
    text-align: left;
    color: white;
  }

  /* ios 11 and higher fix */
  .device-ios{
    .page.page-current.page-with-card-opened {
      margin-top: constant(safe-area-inset-top);
      margin-top: env(safe-area-inset-top);
      transition: margin 0.1s;
    }
    .page.page-current {
      margin-top: 0;
    }
  }
</style>