import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersist from 'vuex-persist'
import config from '../config.json'

Vue.use(Vuex)

// Alle infos: https://alligator.io/vuejs/vuex-persist-state/
const vuexLocalStorage = new VuexPersist({
	key: 'vuex', // The key to store the state on in the storage provider.
	storage: window.localStorage // window.localStorage, // or window.sessionStorage or localForage
	// Function that passes the state and returns the state with only the objects you want to store.
	// reducer: state => state,
	// Function that passes a mutation and lets you decide if it should update the state in localStorage.
	// filter: mutation => (true)
  })

  let initialState = {
	userId: "",
    home: config.init.home,
	map: config.init.map,
	chat: config.init.chat,
	custom1: config.init.custom1,
	custom2: config.init.custom2,
	custom3: config.init.custom3,
	geolocationPermission: undefined,
	version: undefined,
	general:{
		tabChange: false
	},
	savedRequests: [],
  };

export default new Vuex.Store({
  state: initialState,
  
  getters: {
	// Home
	visibleTasks: state => {
		Object.filter = (obj, predicate) => 
    		Object.keys(obj)
          	.filter( key => predicate(obj[key]) )
		  	.reduce( (res, key) => (res[key] = obj[key], res), {} );
		  
		return Object.filter(state.home.tasks, task => task.visible)
	  },
	savedRequests: state => {
		return state.savedRequests.slice().reverse();
	},
  },
  
  mutations: {
	reset (state) {
		Object.keys(state).forEach(key => {
			if(initialState[key]){
				Object.assign(state[key], initialState[key])
			} 
			else if( initialState[key] == undefined) {
				state[key] = undefined;
			}
		})
	  },

	// Genearl
    notificationCount: function(state, payload){
		state[payload.name].notificationCount = payload.value;
	},
	// Tasks
	taskSolved: function(state, payload){
		state.home.tasks[payload.id].solved = payload.value;
	},
	taskVisible: function(state, payload){
		state.home.tasks[payload.id].visible = payload.value;
	},
	taskOpened: function(state, payload){
		state.home.tasks[payload.id].opened = payload.value;
	},
	//Map
	mapPolyLine: function(state, payload){
		state.map.polyLines.push(payload);
	},
	mapMarker: function(state, payload){
		state.map.marker.push(payload);
	},
	chatMessages: function(state, payload){
		state.chat.messagesData.push(payload);
	},
	tabChange: function(state, payload){
		state.tabChange = true;
		setTimeout(function(){
			state.tabChange = false;
		}, 200);
	},
	saveRequest: function(state, payload){
		// Add random id for deleting the saved Request
		payload.id = ([1e7]+1e3).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
		
		state.savedRequests.push(payload);
	},
	deleteRequest: function(state, id){
		var index = state.savedRequests.findIndex(request => request.id == id);
		state.savedRequests.splice(index, 1);
	},
	geolocationPermission: function(state, payload){
		state.geolocationPermission = payload;
	},
	version: function(state, payload){
		state.version = payload;
		// init tabs again
		var views = ["home", "map", "chat", "custom1", "custom2", "custom3"];
		views.forEach( name => {
			Object.assign( state[name], config.init.versions[payload][name] || config.init[name]);
		});
	},
	initMapStore: function(state, payload){
		state.map = payload;
	}
  },
  
  actions: {
    // Here we will create Larry
  },
  plugins: [vuexLocalStorage.plugin]
});


