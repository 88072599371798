<template>
	<f7-popup class="finish-popup">
      <f7-page>
        <f7-navbar title="Geschafft!">
          <f7-nav-right>
            <f7-link popup-close><i class="fa fa-times-circle" aria-hidden="true"></i></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title large>Hurra! 🎉️</f7-block-title>
        <f7-block>
          <b>Du hast es geschafft!</b> 
          <p> Schau mal im Schaufenster in die obere linke Ecke, da kommt etwas für dich heruntergefahren.</p>
          <br>
          <vue-plyr :options="playerOptions">
					<audio controls crossorigin playsinline>
						<source
							src="../../static/media/Ende_Kevin_Lisa.mp3"
							type="audio/mp3"
						/>
					</audio>
					</vue-plyr>
        <br><br><br>
          Wir hoffen dir hat die Schnitzeljagd gefallen. Vielen Dank fürs Spielen. Wir würden uns sehr freuen, wenn du die Schnitzeljagd weiterempfehlen würdest! 
            
            <br> <br>  <b style="text-align: center;">Teilen auf</b> <br><br>
            <f7-button target="_system" external raised fill color="green" href="https://api.whatsapp.com/send?text=Wo%20ist%20Paul%3F%20Die%20W%C3%BCrzburger%20Schnitzeljagd%20%E2%80%94%20https%3A%2F%2Fwuerzburg-schnitzejagd.de%2F"> <i class="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp</f7-button>
            <f7-button target="_system" external raised fill color="blue" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwuerzburg-schnitzejagd.de%2F"><i class="fa fa-facebook" aria-hidden="true"></i> Facebook</f7-button>
            <f7-button target="_system" external raised fill color="lightblue" href="https://twitter.com/intent/tweet?text=Wo%20ist%20Paul%3F%20Die%20W%C3%BCrzburger%20Schnitzeljagd&url=https%3A%2F%2Fwuerzburg-schnitzejagd.de%2F"> <i class="fa fa-twitter" aria-hidden="true"></i> Twitter</f7-button>
            <f7-button target="_system" external raised fill color="gray" href="mailto:?body=Die%20Schnitzeljagd%20habe%20ich%20gerade%20gemacht%2C%20kann%20ich%20dir%20auch%20empfehlen%21%20https%3A%2F%2Fwuerzburg-schnitzejagd.de%2F&subject=Schau%20mal%3A%20Wo%20ist%20Paul%3F%20Die%20W%C3%BCrzburger%20Schnitzeljagd"> <i class="fa fa-envelope" aria-hidden="true"></i> E-Mail</f7-button>
            
            <br>
            <b style="text-align: center;">Fortsetzung folgt!</b> <br>
              <div class="task-solved" style="margin: 0; padding: 0px 0 5px;">
                  <p style="padding: 15px;">Möchtest du über weitere Schnitzeljagden informiert werden? Wir werden deine E-Mail-Adresse lediglich zu diesem Zweck nutzen.</p>
                  <f7-list form no-hairlines-md inset @submit.prevent="newsletterSubmit" style="color: black; margin: -10px 15px 10px;">
                    
                    <f7-list-input
                      label="Deine E-Mail-Adresse"
                      type="email"
                      color="black"
                      placeholder="max@mustermann.de"
                      clear-button
                      required
                      :value="mailAddress"
                      @input="mailAddress = $event.target.value"
                    ></f7-list-input>

                    <f7-block style="padding: 0; margin: 10px 0 0;">
                      <f7-button type="submit" fill raised color="grey" >Absenden</f7-button>
                      
                    </f7-block>
                  </f7-list>
                  </div>
              <br>
        </f7-block>
      </f7-page>
    </f7-popup>
</template>
<script>

	export default {
		data: function () {
			return {
        mailAddress: '',
        rating: 0
      }
		},
		computed:{
       playerOptions(){
        return {
          controls: ['play', 'progress', 'current-time']
        }
      }
    },
		methods:{
      newsletterSubmit: function(){
        var get = { 
          type: 'newsletter',
          mail: this.mailAddress
        };
        this.$f7.request.get(window.serverURL, get);

        this.mailAddress = '';
        this.$f7.dialog.alert('Erfolgreich eingetragen.');
      },

    }
	}
</script>
<style lang="less" >
  canvas#confetti-canvas {
      z-index: 99999;
    }
    .finish-popup.popup .button {
      margin-bottom: 5px;
  }
</style>