<template>
	<f7-card expandable :ref="'card-'+task.id" class="card-tablet-fullscreen card-">
		  <f7-card-content :padding="false">
			<div class="bg-image-card" :style="{height: '300px', backgroundImage: headerImage}">
			  <f7-card-header  text-color="white" class="display-block">
				{{ task.title }}
				<br/>
				<small :style="{opacity: 0.7}">{{totalTasks}}</small>
			  </f7-card-header>
			  <f7-link card-close color="white" class="card-opened-fade-in" :style="{position: 'absolute', right: '15px', top: '15px'}" icon-f7="xmark_circle_fill"></f7-link>
				<f7-chip v-show="storeTask.solved" text="Aufgabe gelöst" media-bg-color="green">
        			<f7-icon slot="media" ios="material:check" aurora="material:check" md="material:check"></f7-icon>
				</f7-chip>
				
				<!--<a class="button button-raised button-fill" style="width: 80px; margin: 10px;"  card-close  @click="markTaskAsSolved()" href="#">Lösen</a>-->

	
			</div>
			<div class="card-content-padding">
				<p v-html="task.description"></p>


				<div class="img-container" >
				<!--	<pinch-zoom v-for="image in task.images" overflow="visible" disableZoomControl="disable"> -->
					<f7-photo-browser
						v-for="(image, index) in task.images" 
						v-if="image" 
						:photos="[image]"
						theme="dark"
						type="popup"
						popup-close-link-text="Schließen"
						:ref="'chat-image'+index"
						:routable-modals="false"
						:toolbar="false"
					></f7-photo-browser>
					<img v-for="(image, index) in task.images" :src="image"  v-if="image" @click="openImage(index, 'chat-image')"/>
					
				<!--	</pinch-zoom>-->
			  	</div>

				<div v-if="(task.popup && task.popup.buttonText && task.popup.title && task.popup.description)">
				<a class="button button-raised popup-open" href="#" :data-popup="'.popup-task-'+task.id" v-html="task.popup.buttonText"></a>
				<div :class="'popup popup-swipe-to-close popup-task-'+task.id">
					<img :src="task.popup.image" class="task-popup-image"/>
					<div class="block-title" v-html="task.popup.title"></div>
					<div class="block">
						<p v-html="task.popup.description"></p>
					</div>
					<div class="block">
						<a class="button button-raised popup-close" href="#">Schließen</a>
					</div>
				</div>
			</div>
			
				<p v-if="task.descriptionAfterImage" v-html="task.descriptionAfterImage"></p>
				<f7-button v-if="task.showChatButton" raised @click="goToChat">Zum Chat</f7-button>

				<!-- Music player -->
				<vue-plyr iconUrl="static/plyr.svg" :loadSprite="false" v-if="task.audio" :options="playerOptions">
					<audio controls crossorigin playsinline>
					<source
						:src="task.audio"
						type="audio/mp3"
					/>
					</audio>
				</vue-plyr>
				<custom-component-1 v-if="task.customView1"></custom-component-1>
			</div>

			
			      

			
			<div class="form-task-wapper" v-show="!storeTask.solved">
				<form  @submit.prevent="onSubmit"  v-if="task.solutions">
					<f7-block><b>{{task.question}}</b></f7-block>
					<f7-block>
						<f7-button raised type="submit" class="submit-plane">
							<f7-icon  size="25px"  f7="paperplane_fill"></f7-icon>
						</f7-button>
					</f7-block>
					<f7-list class="solution-input"  no-hairlines-md>
						
						<f7-list-input ref="solution" 
							:value="userSolution"
    						@input="userSolution = $event.target.value"
							:error-message-force="wrongSolution"
							error-message="Leider falsch."
							label=""
							:type="task.soulutionType ? task.soulutionType : 'text'"
							placeholder="Lösung hier eingeben..."
							:clear-button="true"
							@focus="iOSKeyboard"
							outline
						></f7-list-input>
						
					</f7-list>
					
				</form>
				<br>
				<f7-block><b>Tipps</b></f7-block>
				<f7-list accordion-list>
					<f7-list-item v-for="(hint, index) in task.hints" :title="hint.title" @click="hintClicked(hint.title)" accordion-item >
						<f7-accordion-content>
						<f7-block>
							<p v-html="hint.text">
							</p>
							<div v-if="hint.image">
								<f7-photo-browser
									:photos="[hint.image]"
									theme="dark"
									type="popup"
									popup-close-link-text="Schließen"
									:ref="'hint-image'+index"
									:routable-modals="false"
									:toolbar="false"
								></f7-photo-browser>
								<img style="max-width: 90%; max-height: 300px;" :src="hint.image" @click="openImage(index, 'hint-image')"/>
							</div>
						</f7-block>
						</f7-accordion-content>
					</f7-list-item>
				</f7-list>
				<br>
				<f7-block style="padding-bottom: 10px;">
					<b>Du kommst gar nicht weiter?</b>
				</f7-block>
				<f7-block>
					<f7-row>
						<f7-col  v-if="task.solutions">
							<f7-button raised @click="showSolution">Lösung anzeigen</f7-button>
						</f7-col>
						<f7-col>
							<f7-button raised popup-open=".report-popup" color="red">Problem melden</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>
				<br>
				<br>
				<br>
				<br>
			</div>

				

			<div class="task-solved" v-show="storeTask.solved">
				<div class="card-content-padding">
					<h3 class="text-color-white"><f7-icon slot="media" ios="material:check" aurora="material:check" md="material:check"></f7-icon> Aufgabe gelöst</h3>
					<p v-html="solvedMessage"></p>
					<f7-button style="background: white; color:  #4cd964;" v-if="task.showMapButton" fill raised color="grey" @click="goToMap">Zur Karte</f7-button>
					<f7-button style="background: white; color:  #4cd964;" v-if="task.final" fill raised color="grey" @click="openFinalPopup">{{config.init.home.solutionButtonText ? config.init.home.solutionButtonText : 'Zum Ende'}}</f7-button>
				</div>
			</div>
			<br v-show="storeTask.solved">
			<br v-show="storeTask.solved">
		  </f7-card-content>
	</f7-card>

</template>
<script>
import config from '../../config.json';
import { VueOfflineMixin } from 'vue-offline';
import customComponent1 from './tasks/custom-component-1.vue';

export default {
	props: ['task'],
	mixins: [VueOfflineMixin],
	components: {
		customComponent1
	},

	data: function () {
		return {
			userSolution: null,
			wrongSolution: false,
			config: config,
			finalPopupClass: this.$store.state.home.finalPopupClass || "finish-popup"
		}
	},
	mounted() {
		this.$refs["card-"+this.task.id].$el.addEventListener("click", () => {
			if(!this.$store.state.home.tasks[this.task.id].opened){
				this.$store.commit("taskOpened",{id: this.task.id, value: true});
				this.setChatMessages(this.task.setChatMessages, this.task.chatMessageDelay);
			}
		});
  	},
	computed:{
		solvedMessage(){
			if(this.task.afterSolved && this.task.afterSolved.message){
				return this.task.afterSolved.message;
			}
			else{
				return "";
			}
		},
		storeTask(){
			return this.$store.state.home.tasks[this.task.id]; 
		},
		headerImage(){
			return "url("+this.task.headerImage+")";
		},
		chosenVersion(){
			return this.$store.state.version;
			
		},
		totalTasks(){
			var that = this;
			Object.filter = (obj, predicate) => 
				Object.keys(obj)
					.filter( key => predicate(obj[key]) )
					.reduce( (res, key) => (res[key] = obj[key], res), {} );

			if(this.$store.state.home.tasks){
				var tasks = this.$store.state.home.tasks;
			}
			else{
				var tasks = Object.filter(this.config.tasks, function(task){
					if(task.version){				
						return task.version.includes(that.chosenVersion);
					}else{
						return true;
					}
				}); 
			}
			
			var pos = 0;
			for (const [key, value] of Object.entries(tasks)) {
				pos++;
				if(value.id === that.task.id) break;
			}
			return "Aufgabe "+pos+"/"+Object.entries(tasks).length;
		},
		playerOptions(){
			return {
			controls: ['play', 'progress', 'current-time']
			}
		}
	},
	methods:{
		iOSKeyboard: function(){
			if (typeof device !== 'undefined') {
				if(device.platform != "Android"){
					cordova.plugins.Keyboard.shrinkView(true);
				}
			}
		},openImage: function(index, type){
			if(index!==undefined && this.$refs[type+index] && this.$refs[type+index][0]){
			this.$refs[type+index][0].open();
			}
		},
		markTaskAsSolved(){
				this.$store.commit("taskSolved",{id: this.task.id, value: true});
				var that = this;

				var setMarker = this.task.afterSolved.setMaker;
				if(setMarker){
					setMarker.forEach(marker => {
						that.$store.commit("mapMarker", marker);
					});
					that.$store.commit("notificationCount", {
						name: "map",
						value: 1
					});
				}
				
				var setPolyLines = this.task.afterSolved.setPolyLines;
				if(setPolyLines){
					setPolyLines.forEach(polyLine => {
						that.$store.commit("mapPolyLine", polyLine);
					});
				}
			
				this.setChatMessages(this.task.afterSolved.setChatMessages, this.task.afterSolved.chatMessageDelay);

				
				var showTasks = this.task.afterSolved.showTasks;
				
				if(showTasks){
					/*
					* Fall wenn mehrere Versionen angegeben sind
					* -> Dann ist showTasks ein Object
					*/
					var aShowTaskVersionCombatible = undefined;
					if (typeof showTasks === 'object' && !Array.isArray(showTasks) && showTasks !== null) { 
						var aShowTaskVersion = showTasks[this.$store.state.version];
						if(aShowTaskVersion){
							aShowTaskVersionCombatible = aShowTaskVersion;
						}
					} 
					/*
					* Fall wenn Aufgabe für alle Versionen gilt 
					* -> Dann ist showTasks ein Array
					* -> Wichtig auch für Rückwärtskombatibiblität 
					*/
					else{
						aShowTaskVersionCombatible = showTasks;
					}
					
					aShowTaskVersionCombatible.forEach(id => {
						this.$store.commit("taskVisible",{id: id, value: true});
					});
				}
				var openPopup = this.task.openPopup;
				if(openPopup){
					this.launchConfetti();
					var popupClass = openPopup || "finish-popup";
					this.$f7.popup.get("."+popupClass).open();
				}
				// directly open popup
				if(this.task.final && this.config.init.autoOpenFinal){
					this.launchConfetti();
					this.$f7.popup.get("."+this.finalPopupClass).open();
				}
		},
		setChatMessages: function(setChatMessages, delayInMillis){
				if(setChatMessages){
					var chatCount = 0;
					var delay = delayInMillis || 0;
					var that = this; 

					setTimeout(() => { 
						setChatMessages.forEach(chatMessage => {
							that.$store.commit("chatMessages", chatMessage);
							if(chatMessage.type=="received"){
								chatCount++;
							}
						});
						that.$store.commit("notificationCount", {
							name: "chat",
							value: chatCount
						});
						var notificationActive = (that.$store.state.chat.notificationActive !== undefined) ? that.$store.state.chat.notificationActive : true; 
						if(notificationActive && chatCount){
							var notificationFull = this.$f7.notification.create({
								icon: '<i class="icon material-icons">chat</i>',
								title: that.$store.state.chat.title,
								titleRightText: 'Jetzt',
								subtitle: chatCount+' neue Nachrichten',
								text: 'Klicke, um zum Chat zu gelangen',
								closeButton: true,
								//closeOnClick: true,
								on: {
									click: function () {
											that.$f7.card.close('.card-opened', false);
											that.$forceUpdate(); 
											that.$f7.tab.show("#view-chat");
											that.$store.commit("notificationCount", {
											name: "chat",
											value: 0
										});
									},
								},
							});
						
							notificationFull.open();
						}
					}, delay);	
				}
		},
		onSubmit: function(){
			var correctAnswer = false;
			var that = this; 
			
			if (
				that.userSolution &&
				that.userSolution.trim().length > 0 &&
				this.task.solutions.findIndex(item => that.userSolution.toLowerCase().trim() === item.toLowerCase()) >= 0 && 
				!this.storeTask.solved
			) {
				this.markTaskAsSolved();
				correctAnswer = true;
			}else{
				const that = this;
				that.wrongSolution = true; 
				setTimeout(function(){that.wrongSolution = false}, 3000);
			}
			
			var get = { 
				riddle: this.task.id,
				type: 'riddle',
				solution: this.userSolution,
				userId: this.$store.state.userId,
				correctAnswer: correctAnswer
			};

			this.sendGetRequest(get);

			//this.userSolution = "";
		},
		hintClicked: function(hintTitle){
			var get = { 
				riddle: this.task.id,
				type: 'action',
				actionType: 'hint',
				action: hintTitle,
				userId: this.$store.state.userId
			};
			this.sendGetRequest(get);
		},
		showSolution: function(){
			var that = this;
			this.$f7.dialog.confirm('Bist du sicher, dass du nicht weiter rätseln, sondern die Lösung sehen willst?', "Lösung anzeigen", function () {
				that.$f7.dialog.alert('Die Lösung ist <b>'+that.task.solutions[0]+'</b>.', "Lösung");
				var get = { 
					riddle: that.task.id,
					type: 'action',
					actionType: 'solution',
					action: that.task.solutions[0],
					userId: that.$store.state.userId
				};
				that.sendGetRequest(get);
				that.markTaskAsSolved();
			});
		},
		openFinalPopup(){
			this.launchConfetti();
			this.$f7.popup.get("."+this.finalPopupClass).open();
		},
		goToMap(){
			this.$store.commit("notificationCount", {
				name: "home",
				value: 1
			});
			this.$f7.card.close('.card-opened', false);
			this.$forceUpdate(); 
			this.$f7.tab.show("#view-map");
			this.$store.commit("notificationCount", {
				name: "map",
				value: 0
			});
			
		},
		goToChat(){
			this.$f7.card.close('.card-opened', false);
			this.$forceUpdate(); 
			this.$f7.tab.show("#view-chat");
			this.$store.commit("notificationCount", {
				name: "chat",
				value: 0
			});
			
		},
		launchConfetti: function(){
			this.$confetti.start({
				particlesPerFrame: 0.25,
				});
			var that = this;
			setTimeout(function(){
				that.$confetti.stop();
			}, 5000);
		},
	}
	
}
</script>
<style lang="less" >
	
	.bg-image-card{
		background-size: cover;
		background-position: center center;
		color: white;
		position: relative;
		max-width: 700px;
	
		&::before {
			content: "";
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			max-height: 300px;
			background-image: linear-gradient(115deg, rgba(33, 43, 52, 0.8), rgba(33, 43, 52, 0.3));
		}

		.card-header {
			padding-top: 10px;
			
		}
		.chip{
			background: white;
			position: absolute;
			left: 15px;
			bottom: 20px;
		}
	}
	.task-solved {
		background: #4cd964;
		background: linear-gradient(159deg, #4cd964, #28bf72, #009688);
		margin: 0 14px 14px;
    	color: white;
		h3{
			margin: 0 0 -8px;
		}
		button{
			background: white; 
			color: #4cd964;
		}
	}

	.solutionFeedback {
		background: #f64747;
		color: white;
		padding: 15px;
		transition: all;
	}
	.img-container img {
		max-width: 100%;
	}
	button.submit-plane.button {
		text-overflow: initial;
		height: 40px;
		width: 45px;
		position: absolute;
		right: 15px;
		top: 7px;
	}
	
	.solution-input {
		width: calc(~"100% - 50px");
	}

	.chip-media.bg-color-green {
    background-color: #4cd964 !important;
}
.task-popup-image{
	width: 100%;
}
</style>