<template>
  <f7-page>
    <f7-navbar :title="$store.state.chat.title">
      <f7-nav-right>
        <f7-link panel-open="right">
          <f7-icon  ios="f7:bars" aurora="f7:bars" md="material:menu" ></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-messages ref="messages">
      <f7-messages-title><b>Sonntag,</b> 12:58</f7-messages-title>
      
        <f7-message
          v-for="(message, index) in messagesData"
          :key="index"
          :type="message.type"
          :name="message.name"
          :avatar="message.avatar"
          :first="isFirstMessage(message, index)"
          :last="isLastMessage(message, index)"
          :tail="isTailMessage(message, index)"
        >
          <span slot="image" >
            <f7-photo-browser
              :photos="[message.image]"
              theme="dark"
              type="popup"
              popup-close-link-text="Schließen"
              :ref="'chat-image'+index"
              :routable-modals="false"
		          :toolbar="false"
            ></f7-photo-browser>
              <img :src="message.image"  v-if="message.image" @click="openImage(index)"/>
          </span>
          <span slot="text" v-if="message.text && message.header != 'audio'" v-html="message.text"></span>
          <vue-plyr iconUrl="static/plyr.svg" :loadSprite="false" v-if="message.header == 'audio'" :options="playerOptions">
            <audio controls crossorigin playsinline>
              <source
                :src="message.text"
                type="audio/mp3"
              />
            </audio>
            </vue-plyr>
        </f7-message>
      

    </f7-messages>
  </f7-page>
</template>
<script>
	import config from '../config.json';

  export default {
    data() {
      return {
        attachments: [],
        sheetVisible: false,
        typingMessage: null,
        config: config
      }
    },
    computed: {
      messagesData (){
        return this.$store.state.chat.messagesData;
      },
      playerOptions(){
        return {
          controls: ['play', 'progress', 'current-time']
        }
      }
    },
    mounted() {
      const self = this;
      self.$f7ready(() => {
       // self.messagebar = self.$refs.messagebar.f7Messagebar;
        self.messages = self.$refs.messages.f7Messages;
      });
       this.$$(this.$el).parents('.view').on('tab:show', this.onTabShow);
    },
    methods: {
      onTabShow() {
        this.$f7.notification.close();
      },
      isFirstMessage(message, index) {
        const self = this;
        const previousMessage = self.messagesData[index - 1];
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
      },
      isLastMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      isTailMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      openImage: function(index){
        if(index!==undefined && this.$refs['chat-image'+index] && this.$refs['chat-image'+index][0]){
          this.$refs['chat-image'+index][0].open();
        }
      }
    }
  };
</script>
<style>
 .message {
    max-width: 90%;
}
body .message:not(.message-last) .message-avatar{
  opacity: 1;
}
</style>