// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from './framework7-custom.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import '../css/framework7-custom.less';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';

// Import store
import store from '../store/store';

import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

import VueConfetti from 'vue-confetti';

import VueOffline from 'vue-offline'


import config from '../config.json';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

window.serverURL = config.init.serverURL;  //"http://localhost/schnitzeljagd/" // https://lukasduerrbeck.de/schnitzeljagd/ajax.php
window.isOnline = true; 



Vue.use(VuePlyr, {
  plyr: {}
});

Vue.use(VueConfetti);
Vue.use(VueOffline, {
  mixin: false
});
Vue.mixin({
  methods: {
    sendGetRequest: function (get) {
      if(!config.init.sendStats){
        return;
      }
      if(this.isOnline){
        this.$f7.request.get(window.serverURL, get);
      } else {
        this.$store.commit("saveRequest", get); // Save log to send it later
      }
      window.isOnline = this.isOnline;
    }
  }
});


// Init App
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  }
});
