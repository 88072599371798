<template>
  <f7-page name="map">
    <f7-navbar title="Karte">
      <f7-nav-right>
        <f7-link panel-open="right">
          <f7-icon  ios="f7:bars" aurora="f7:bars" md="material:menu" ></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div id="map"></div>
  </f7-page>
</template>
<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.js';
import 'font-awesome/css/font-awesome.min.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import leaf from '../assets/marker-icon-2x.png';
import leafShaddow from '../assets/marker-shadow.png';
import locationMarker from '../assets/location.png';
import config from '../config.json';

export default {
 name: "Map",
 data() {
   
   return{
     map: null,
     leaf: leaf,
     mapConfig: this.$store.state.map,
     leafShaddow: leafShaddow,
     locationMarker: locationMarker,
     current_position: undefined, 
     current_accuracy: undefined,
     config: config,
     clicks: [],
     lastLine: undefined,
     drawMode: false,
     geolocationError: false,
     userMarker: undefined
   }},
 methods: {
   setupLeafletMap: function () {
      // reset map if changed
      if (this.map != undefined) {
        this.map.off();
        this.map.remove(); 
      }
 
      if(this.mapConfig.mode == "image"){
        this.map = L.map('map', {
          crs: L.CRS.Simple,
          minZoom: -3
        });
        
        var yx = L.latLng;

        var xy = function(x, y) {
          if (L.Util.isArray(x)) {    // When doing xy([x, y]);
            return yx(x[1], x[0]);
          }
          return yx(y, x);  // When doing xy(x, y);
        };

        var bounds = [[0,0], this.mapConfig.bounds]; /* for some reason x and y are changed first y than x */
        var image = L.imageOverlay( this.mapConfig.imageSrc, bounds).addTo(this.map);

        this.map.setView(this.mapConfig.center, -2);  /* for some reason x and y are changed first y than x */
        
        
      }
      else{
        this.map = L.map("map").setView(this.mapConfig.center, 16);
        
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:'Map data (c) OpenStreetMap contributors, CC-BY-SA, Imagery (c) Mapbox',
            maxZoom: 20,
            id: "mapbox/streets-v11",
            accessToken: 'pk.eyJ1IjoibmFzZW5mbG9ldGUiLCJhIjoiY2owcmFsZW85MDAydDJxb2tqcnExbTlueCJ9.E_S4EyOZYyrBMot5NlL8Ug',
          }
        ).addTo(this.map);

        if(this.$store.state.geolocationPermission){
          this.addGeolocationDisplay();
        }
      }

      if(this.drawMode){
          var that = this; 
          this.map.on('click', function(e) {
            that.clicks.push([e.latlng.lat,e.latlng.lng]);
            if(that.clicks.length > 1){
              var coords = [that.clicks.at(-1),that.clicks.at(-2)];
              that.lastLine = L.polyline(coords, {color: "red"}).addTo(that.map);
            }
          });
          
          window.addEventListener("keypress", e => {
            if(String.fromCharCode(e.keyCode)=="p"){
              console.log(that.clicks);
              var previousPoint = false;
              var distance = 0;
              that.clicks.forEach(function (coord) {
                var latLng = L.latLng(coord[0], coord[1]);
                if (previousPoint) {
                  distance += previousPoint.distanceTo(latLng);
                }
                previousPoint = latLng;
              });
              console.log(distance);
            }
            if(String.fromCharCode(e.keyCode)=="q"){
              that.clicks.pop();
              that.lastLine.remove(that.map);
            }
          });
        }

    


    

    },
    onTabShow() {
      this.map.invalidateSize();
      var that = this;
      if(this.$store.state.geolocationPermission === undefined && this.mapConfig.mode != "image"){
        
        
        this.$f7.dialog.create({
          'title': 'GPS Position freigeben?', 
          'text': 'Durch die Freigabe wird dein aktueller Standort auf der Karte angezeigt. Dies dient zur einfacheren Orientierung. Alle Rätsel sind auch ohne Standortfreigabe lösbar. Der GPS-Standort wird nur lokal verarbeitet und zu keiner Zeit an den Server gesendet.',
          buttons: [
            {
              text: 'Ok',
              onClick: function () {
                that.$store.commit("geolocationPermission", true);
                that.addGeolocationDisplay();
              }
            },
            {
              text: 'Nein danke',
              onClick: function () {
                that.$store.commit("geolocationPermission", false);
              }
            }
          ],
          verticalButtons: true,
        }).open();
        
      }
    }, 
    addGeolocationDisplay: function(){
      
      
      this.map.on('locationerror', function(){});

      var settings = {
          position: 'topright',
          strings: {
            title: "Deine aktuelle Position"
          },
          setView: false,
          flyTo: true,
          keepCurrentZoomLevel: true,
          showCompass: false,
          enableHighAccuracy: true
        };


      var lc = L.control.locate(settings).addTo(this.map);
      lc.start();
      

     // navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);

      

    },
    /*
    updateUserPosition: function(newLatLng){
      if(!this.userMarker){
          this.updateUserPosition = L.marker(newLatLng).addTo(this.map);
      }
    },
    

    onSuccess: function(pos) {
            var lat = pos.coords.latitude;
            var lng = pos.coords.longitude;
            var newLatLng = new L.LatLng(lat, lng);
           this.updateUserPosition(newLatLng);

        },

     onError: function(error) {
            alert('code: ' + error.code + '\n' + 'message: ' + error.message + '\n');
        },
*/
   renderPOIs: function(){
          var orangeIcon = L.icon({
            iconUrl: this.leaf,
            shadowUrl: this.leafShaddow,

            iconSize:     [14, 24], // size of the icon
            shadowSize:   [12, 12], // size of the shadow
            iconAnchor:   [7, 24], // point of the icon which will correspond to marker's location
            shadowAnchor: [7, 24],  // the same for the shadow
            popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
        });

       this.mapConfig.pois.forEach(entry => {
         L.marker(entry.coords, {icon: orangeIcon})
         .addTo(this.map)
         .bindPopup('<b>'+entry.title+'</b><br>'+entry.description);
       });
     },
     renderMarker: function(){
       this.$store.state.map.marker.forEach(entry => {
         
         L.marker(entry.coords)
         .addTo(this.map)
         .bindPopup('<b>'+entry.title+'</b><br>'+entry.description);
       });
     },
     renderPolyLines: function(){
       this.$store.state.map.polyLines.forEach(entry => {
         L.polyline(entry.coords, {color: entry.color}).addTo(this.map);
       });
     },
     windowResize: function(){
       this.map.invalidateSize();
     }
 },
 watch: {
    '$store.state.map.center': function() {
      this.setupLeafletMap();
    },
    '$store.state.map.polyLines': function() {
      this.renderPolyLines();
    },
    '$store.state.map.marker': function() {
      this.renderMarker();
    },
    '$store.state.tabChange': function() {
      if(this.$store.state.tabChange){
        this.map.invalidateSize();
      }
    }
  },
 mounted() { this.$nextTick(() => {
   this.setupLeafletMap();
   this.renderPOIs();
   this.renderPolyLines(); 
   this.renderMarker();
   window.addEventListener('resize', this.windowResize);
  this.$$(this.$el).parents('.view').on('tab:show', this.onTabShow);

 });
 },
};
</script>

<style >
#map{
      max-width: 700px;
}
#mapContainer {
 width: 80vw;
 height: 100vh;
}
.leaflet-control-attribution a{
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}
</style>