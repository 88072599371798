<template>
	<f7-popup class="about-popup">
      <f7-page>
        <f7-navbar title="Über die Schnitzeljagd ">
          <f7-nav-right>
            <f7-link popup-close>Schließen</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <b>Die Schnitzeljagd ist ein Projekt der CityChurch Würzburg.</b>
          <p>Die CityChurch ist eine evangelische Freikirche und wurde in 2003 gegründet. </p>
          <p>Wir finden Würzburg großartig, schätzen gutes Leben und meinen, Glaube ist nichts von gestern. Deswegen findest du bei uns zwar altbekannte Glaubensinhalte, aber im Kontext von je aktuellen Lebensfragen und in Formen verpackt, die unserer Kultur entsprechen. Die CityChurch hat kein eigenes Kirchengebäude, sondern feiert einen Gottesdienst am Sonntagmorgen im Club (Mannygreen) und während des Semesters am Sonntagabend in der Gerber Lounge. Dazu gibt es Treffen in kleinen Gruppen in Wohnzimmern und Kneipen, wir feiern gerne Partys am Main oder machen zusammen Urlaub. </p>
          <p>Die Abendkirche, der Abendgottesdienst der CityChurch, ist vor allem studentisch geprägt. Da ist die Idee der Schnitzeljagd entstanden: es wäre doch mega, wenn vor allem Ersties, aber auch allen anderen Motivierten, unser geliebtes Würzburg rätselhaft kennen lernen könnten! Aus einer Idee in Corona-Zeiten wurde hiermit Realität und wir freuen uns riesig, wenn sie viele Menschen begeistert, genutzt und weiterempfohlen wird.  </p>
          <p>Falls du mehr über uns wissen magst, schau auf unserer Homepage oder Instagram vorbei:</p>
            <f7-button target="_system" external raised href="http://citychurch.de/">CityChurch.de</f7-button>
            <f7-button target="_system" external raised href="https://www.instagram.com/citychurch_abendkirche/ ">Instagram Abendkirche</f7-button>
            <f7-button target="_system" external raised href="https://www.instagram.com/citychurch_morgenkirche/">Instagram Morgenkirche</f7-button>
        </f7-block>
      </f7-page>
    </f7-popup>
</template>
<script>
	export default {
		data: function () {
			return {}
		},
		computed:{},
		methods:{}
	}
</script>
<style lang="less" >
</style>