<template>
	<f7-popup class="report-popup popup-tablet-fullscreen">
      <f7-page>
        <f7-navbar title="Feedback oder Problem melden">
          <f7-nav-right>
            <f7-link popup-close>Schließen</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list v-if="isOnline" form no-hairlines-md @submit.prevent="reportSubmit">
          <f7-list-input
            label="Name"
            type="text"
            placeholder="Dein Name"
            clear-button
            :value="msgName"
    				@input="msgName = $event.target.value"
          ></f7-list-input>

          <f7-list-input
            label="E-mail für Rückfragen"
            type="email"
            placeholder="Deine E-Mail"
            clear-button
            :value="msgMail"
    				@input="msgMail = $event.target.value"
          ></f7-list-input>

          <f7-list-input
            label="Beschreibung des Problems*"
            type="textarea"
            placeholder="Was ist das Problem?"
            required
            :value="msgText"
    				@input="msgText = $event.target.value"
          ></f7-list-input>
          <f7-block>
            <f7-button type="submit" fill >Absenden</f7-button>
          </f7-block>
        </f7-list>
        <f7-card v-else :content="offlineMessage">
          <span slot="header">Du bist offline</span>
        </f7-card>
      </f7-page>
    </f7-popup>
</template>
<script>
import { VueOfflineMixin } from 'vue-offline';
import config from '../../config.json';
	export default {
		data: function () {
			return {
         msgName: '',
        msgMail: '',
        msgText: '',
        offlineMessage: config.init.offlineMessage ?  config.init.offlineMessage : "Verbindung fehlgeschlagen. Bitte prüfe, ob dein Gerät mit dem Internet verbunden ist."  
      }
		},
     mixins: [VueOfflineMixin],
		methods:{
       reportSubmit: function(){
          var get = { 
            type: 'report',
            name: this.msgName,
            mail: this.msgMail,
            text: this.msgText,
            userId: this.$store.state.userId
          };
          this.sendGetRequest(get);
          this.$f7.popup.close();
          this.msgName = '';
          this.msgMail = '';
          this.msgText = '';
          this.$f7.dialog.alert('Erfolgreich versendet.');
      },
    }
	}
</script>
<style lang="less" >
</style>