<template>
  <f7-page name="home">
	<f7-navbar title="Aufgaben">
		<f7-nav-right>
			<f7-link panel-open="right">
				<f7-icon  ios="f7:bars" aurora="f7:bars" md="material:menu" ></f7-icon>
			</f7-link>
		</f7-nav-right>
	</f7-navbar>
	<basic-task v-for="task in visibleTasks" :task="tasks[task.id]"></basic-task>		
 
  </f7-page>
</template>

<script>
	import config from '../config.json';

	import BasicTask from '../components/home/basic-task.vue';
	
	export default {
		components: {
			BasicTask
		},
		computed:{
			visibleTasks(){
				return this.$store.getters.visibleTasks; 
			},
		},
		data() {
   			return{
				tasks: config.tasks,
			}
		}
	}
</script>