<template>
  <f7-page name="Custom2">
	<f7-navbar title="Custom2">
		<f7-nav-right>
			<f7-link panel-open="right">
				<f7-icon  ios="f7:bars" aurora="f7:bars" md="material:menu" ></f7-icon>
			</f7-link>
		</f7-nav-right>
	</f7-navbar>
 
  </f7-page>
</template>







<script>	
	export default {
		components: {},
		computed:{},
		data() {
   			return{
			}
		}
	}
</script>