
import HomePage from '../pages/home.vue';
import MapPage from '../pages/map.vue';
import ChatPage from '../pages/chat.vue';
import CustomPage1 from '../pages/custom1.vue';
import CustomPage2 from '../pages/custom2.vue';
import CustomPage3 from '../pages/custom3.vue';
import config from '../config.json';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },

 
  {
    path: '/map/',
    component: MapPage,
  },
  {
    path: '/chat/',
    component: ChatPage,
  },
  {
    path: config.customPages.custom1.path,
    component: CustomPage1,
  },
  {
    path: config.customPages.custom2.path,
    component: CustomPage2,
  },
  {
    path: config.customPages.custom3.path,
    component: CustomPage3,
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
